import { useEffect, useRef, useState } from 'react';
import { useSessionStorageState } from 'ahooks';
import type { Coordinate } from '~/modules/location/hook/use-current-coordinates';
import { getCoordinatesByBrowser } from '~/modules/location/hook/use-current-coordinates';
import { useCurrentCoordinates } from '~/modules/location/hook/use-current-coordinates';
import { useLogger } from '@archipro-website/logger/client';

interface BasicLocationDateType {
    MapCoordinates?: Coordinate;
    distance?: number;
}

const COORD_SESSION_STORAGE_NAME = 'visitor-current-coordinates';

export const useHasSavedCoords = () => {
    const [savedCoords] = useSessionStorageState<Coordinate | undefined>(
        COORD_SESSION_STORAGE_NAME
    );
    return !!savedCoords;
};

export const useLocationsSortByDistance = <
    T extends BasicLocationDateType
>(opts: {
    locations: T[];
    existingUserCoordinates?: Coordinate;
    skip?: boolean;
}): {
    sortedLocations: T[];
    userLocationLoaded: boolean;
    calculateDistance: () => Promise<boolean>;
    refetchSortedLocations: () => Promise<T[]>;
} => {
    const logger = useLogger();
    const { locations, existingUserCoordinates, skip } = opts;
    const [newLocations, setNewLocations] = useState<T[]>(locations || []);
    const [userLocationLoaded, setUserLocationLoaded] = useState(false);

    const [savedCoords, setSavedCoords] = useSessionStorageState<
        Coordinate | undefined
    >(COORD_SESSION_STORAGE_NAME);

    const isBrowserGeoEnabled =
        typeof navigator !== 'undefined' && 'geolocation' in navigator;

    const defaultCoords =
        existingUserCoordinates ||
        (isBrowserGeoEnabled ? savedCoords : undefined);

    const { userCoordinates, findUserCoordinates, loaded } =
        useCurrentCoordinates(defaultCoords, skip);

    const isDistanceUpdatedRef = useRef(false);

    const isValidUserCoordinates = isValidCoordinates(userCoordinates);

    const refetchSortedLocations = async () => {
        try {
            const userGeoCoords = await getCoordinatesByBrowser();
            if (userGeoCoords && isValidCoordinates(userGeoCoords)) {
                setSavedCoords(userGeoCoords);
                return addDistanceToLocations(locations, userGeoCoords);
            }
        } catch (error) {
            logger.error('refetchSortedLocations err', error);
        }
        return locations;
    };

    const calculateDistance = async () => {
        const locations = await refetchSortedLocations();
        setNewLocations(locations);
        return locationHasDistance(locations);
    };

    useEffect(() => {
        if (!isValidUserCoordinates) {
            if (loaded) {
                setUserLocationLoaded(true);
            } else {
                findUserCoordinates();
            }
        } else {
            setUserLocationLoaded(true);
        }
    }, [findUserCoordinates, loaded, isValidUserCoordinates]);

    useEffect(() => {
        if (
            isValidUserCoordinates &&
            isValidCoordinates(locations[0]?.MapCoordinates) &&
            !locationHasDistance(locations) &&
            !isDistanceUpdatedRef.current
        ) {
            const tempLocations = addDistanceToLocations<T>(
                locations,
                userCoordinates
            );

            setNewLocations(tempLocations);
            isDistanceUpdatedRef.current = true;
            if (isBrowserGeoEnabled) {
                setSavedCoords(userCoordinates);
            }
        }
    }, [
        locations,
        userCoordinates,
        isValidUserCoordinates,
        isBrowserGeoEnabled,
        setSavedCoords,
    ]);

    return {
        sortedLocations: newLocations,
        userLocationLoaded,
        calculateDistance,
        refetchSortedLocations,
    };
};

const coordinateDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
): number => {
    const radLat1 = (Math.PI * lat1) / 180;
    const radLat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radTheta = (Math.PI * theta) / 180;
    let dist =
        Math.sin(radLat1) * Math.sin(radLat2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    return Math.floor(dist);
};

const addDistanceToLocations = <T extends BasicLocationDateType>(
    locations: T[],
    userCoordinates: Coordinate
): T[] => {
    const newLocations = JSON.parse(JSON.stringify(locations));
    newLocations.map((locationData: T) => {
        locationData.distance = coordinateDistance(
            locationData?.MapCoordinates?.Latitude || 0,
            locationData?.MapCoordinates?.Longitude || 0,
            userCoordinates.Latitude || 0,
            userCoordinates.Longitude || 0
        );
        return locationData;
    });
    return newLocations.sort(
        (a: T, b: T) => (a?.distance || 0) - (b?.distance || 0)
    );
};

export const isValidCoordinates = (MapCoordinates?: Coordinate): boolean => {
    if (!MapCoordinates) {
        return false;
    }
    return !(MapCoordinates.Latitude === 0 && MapCoordinates.Longitude === 0);
};

export const locationHasDistance = (
    locations: BasicLocationDateType[]
): boolean => {
    return (
        !!locations?.[0] &&
        Object.prototype.hasOwnProperty.call(locations[0], 'distance')
    );
};
